import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  news: [],
};

const newsSlice = createSlice({
  name: "news",
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload;
    },
  },
});

const placesSlice = createSlice({
  name: "places",
  initialState: {
    places: [],
  },
  reducers: {
    addPlaces: (state, action) => {
      const newPlaces = action.payload;
      newPlaces.forEach((newPlace) => {
        const existingPlaceIndex = state.places.findIndex(
          (place) => place.id === newPlace.id
        );
        if (existingPlaceIndex !== -1) {
          const existingPlace = state.places[existingPlaceIndex];
          state.places[existingPlaceIndex] = {
            ...existingPlace,
            count: newPlace.count || existingPlace.count || 0,
          };
        } else {
          state.places.push(newPlace);
        }
      });
    },
    updatePlaceCount: (state, action) => {
      const { placeId, count } = action.payload;
      const placeIndex = state.places.findIndex(
        (place) => place.id === placeId
      );
      if (placeIndex !== -1) {
        state.places[placeIndex].count = count;
      }
    },
  },
});


const grainTypesSlices = createSlice({
  name: "grainTypes",
  initialState: {
    grainTypes: [],
  },
  reducers: {
    addGrainTypes: (state, action) => {
      state.grainTypes = action.payload;
    },
  },
});

const footerSlices = createSlice({
  name: "footer",
  initialState: {
    footer: [],
  },
  reducers: {
    addFooter: (state, action) => {
      state.footer = action.payload;
    },
  },
});

const homeNewsSlices = createSlice({
  name: "homeNews",
  initialState: {
    homeNews: [],
  },
  reducers: {
    addhomeNews: (state, action) => {
      state.homeNews = action.payload;
    },
  },
});
const homeRiceSlices = createSlice({
  name: "homeRiceTypes",
  initialState: {
    homeRiceTypes: [],
  },
  reducers: {
    addhomeRiceTypes: (state, action) => {
      state.homeRiceTypes = action.payload;
    },
  },
});
const homePlaceSlices = createSlice({
  name: "homePlace",
  initialState: {
    homePlace: [],
  },
  reducers: {
    setHomePlace: (state, action) => {
      state.homePlace = action.payload;
    },
  },
});

const footerGrainSLices = createSlice({
  name: "footerGrainTypes",
  initialState: {
    footerGrainTypes: [],
  },
  reducers: {
    addfootergrainTypes: (state, action) => {
      state.footerGrainTypes = action.payload;
    },
  },
});
export const { setNews } = newsSlice.actions;
export default newsSlice.reducer;
export const { addPlaces } = placesSlice.actions;
export { placesSlice };
export const { updatePlaceCount } = placesSlice.actions;
export const { addGrainTypes } = grainTypesSlices.actions;
export { grainTypesSlices };
export const { addFooter } = footerSlices.actions;
export { footerSlices };
export const { addhomeNews } = homeNewsSlices.actions;
export { homeNewsSlices };
export const { addhomeRiceTypes } = homeRiceSlices.actions;
export { homeRiceSlices };
export const { addfootergrainTypes } = footerGrainSLices.actions;
export { footerGrainSLices };
export const { setHomePlace } = homePlaceSlices.actions;
export { homePlaceSlices };

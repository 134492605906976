import React from "react";
import { useEffect, useState } from "react";

const Loader = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <div class="loader">
        <div class="scanner">
          <span>Loading... adnjadnajdn</span>
        </div>
      </div>
      {/* <div id="container">
        <label class="loading-title">Loading ...</label>
        <span class="loading-circle sp1">
          <span class="loading-circle sp2">
            <span class="loading-circle sp3"></span>
          </span>
        </span>
      </div> */}
    </>
  );
};

export default Loader;

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Navbar from "../drawer/Navbar";
import Drawer from "../drawer/Drawer";
import { useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const UpdateAContact = () => {
  const { state } = useLocation();
  const [updateAddress, setUpdateAddress] = useState("");

  useEffect(() => {
    console.log("Update Data", state);
    setUpdateAddress(state.address || "");
  }, []);

  const dataCollection = collection(db, "admincontact");

  const handleUpdateData = async (e, id) => {
    e.preventDefault();
    console.log(id);

    const docUpdate = doc(dataCollection, id);

    updateDoc(docUpdate, {
      address: updateAddress,
    })
      .then(() => {
        alert("Data update");
      })
      .catch((error) => {
        alert("Error Message", error);
      });
  };

  //   const handleIconChange = (e) => {
  //     setIconUrl(URL.createObjectURL(e.target.files[0]));
  //     setIcon(e.target.files[0]);
  //   };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={9} container>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <TextField
              className="addServices__input"
              type="text"
              name="detail"
              placeholder="Updated Address"
              value={updateAddress}
              onChange={(e) => {
                setUpdateAddress(e.target.value);
              }}
              sx={{
                width: "45%",
                marginBottom: "20px",
              }}
            />
          </Box>
          <Grid container>
            <Button
              variant="contained"
              type="submit"
              onClick={(e) => handleUpdateData(e, state.id)}
              className="admin_btn"
            >
              Update Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateAContact;

import React, { useState, useEffect } from "react";
import PrimarySearchAppBar from "../../components/header/Drawer";
import "./Contact.css";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import headerLogo from "../../assests/images/logo.png";
import { Grid, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import Footer from "../../components/footer/Footer";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import BgHeader from "../../components/bg_Header/BgHeader";
import Loader from "../../components/loader/Loader";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const contactCollection = collection(db, "contact");

  const handleAddData = async (e) => {
    e.preventDefault();
    if (name === "" || email === "" || message === "") {
      setErrorMessage("Please fill  all the fields.");
      return;
    }
    try {
      await addDoc(contactCollection, {
        name: name,
        email: email,
        message: message,
      });
      alert("Data Sent");
      setName("");
      setEmail("");
      setMessage("");
      setErrorMessage("");
    } catch (error) {
      alert("Error", error);
    }
  };

  const [adminContact, getAdminContact] = useState([]);
  useEffect(() => {
    const adminContactCollection = collection(db, "admincontact");
    const getData = async () => {
      const data = await getDocs(adminContactCollection);
      getAdminContact(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    getData();
  }, []);

  return (
    <>
    
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <div className="contact__page">
        <Grid container>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <CardContent>
              <Typography gutterBottom variant="h6" className="news__head">
                Contact Us
              </Typography>
            </CardContent>
          </Grid>
          <Grid item lg={9}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <p className="news__address">Business address:</p>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={2}>
                  {adminContact.map((data) => {
                    return (
                      <Grid item key={data.id} lg={12} md={12} xs={12}>
                        <>
                          <Typography variant="h5" component="p">
                            {data.address}
                          </Typography>
                        </>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
        <Grid container sx={{ marginTop: "20px" }}>
          <Grid item lg={9}>
            <Grid container>
              <Grid item lg={9}>
                <Box sx={{ backgroundColor: "white", padding: "" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Typography>
                        If you have a question or would like more information,
                        please fill out and submit the form below.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={7}>
                      <TextField
                        label=" Name"
                        fullWidth
                        className="cardDetail__input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} lg={7}>
                      <TextField
                        type="email"
                        label="Email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      <Typography
                        sx={{ fontWeight: "700", padding: "10px" }}
                      ></Typography>
                      <TextField
                        type="text"
                        label="Message"
                        multiline
                        rows={4}
                        fullWidth
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddData}
                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {errorMessage && <p className="error">{errorMessage}</p>}
                      {/* Display the error message */}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default Contact;

import React from "react";
import { Grid, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./CardDetail.css";
import img1 from "../../assests//cardsimg/card1.jpg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailIcon from "@mui/icons-material/Email";
import PrimarySearchAppBar from "../header/Drawer";
import headerLogo from "../../assests/images/logo.png";
import { useLocation } from "react-router-dom";
import Footer from "../footer/Footer";
import { FacebookShareButton } from "react-share";
import { TwitterShareButton } from "react-share";
import BgHeader from "../../components/bg_Header/BgHeader";

const CardDetail = () => {
  const { state } = useLocation();
  console.log("state....", state);
  const location = useLocation();
  const currentURL = `${window.location.origin}${location.pathname}`;
  console.log(currentURL);

  const handleShare = () => {
    console.log("Sharing on Facebook...");
  };

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <div className="card__detail">
        <Grid container>
          <Grid item lg={9}>
            <Grid container>
              <Grid item lg={12}>
                <p variant="h4" component="p" className="cardDetail__txt">
                  Paraway lists NSW properties with $180M price hope
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>

        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item lg={9} md={8} sm={12} className="cardDetail__border">
            <Grid container>
              <Grid item lg={9} md={6} sm={12} xs={12}>
                <Typography variant="h6" component="p">
                  Linda Rowley, May 16, 2023
                </Typography>
              </Grid>
              <Grid item lg={3} md={6} sm={12} xs={12}>
                <Box className="card__icons">
                  <TwitterShareButton url={currentURL} onClick={handleShare}>
                    <TwitterIcon className="icon2" />
                  </TwitterShareButton>
                  <FacebookShareButton url={currentURL} onClick={handleShare}>
                    <FacebookIcon className="icon1" />
                  </FacebookShareButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item lg={3} md={8} sm={12}></Grid>
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Grid container>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <img src={state.image} alt="" />
              </Grid>
              <Grid item lg={3}>
                <Typography variant="h6" component="p"></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <Grid container>
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Typography variant="h6" component="p">
                  {state.title}
                </Typography>

                {/* <Typography>{state.detail}</Typography> */}
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: state.detail,
                  }}
                ></Typography>
              </Grid>
              <Grid item lg={3}></Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item lg={9}>
            <Grid container>
              <Grid item lg={9}>
                <Box sx={{ backgroundColor: "white", padding: "20px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} lg={12}>
                      <TextField
                        label=" Name"
                        fullWidth
                        className="cardDetail__input"
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <TextField label="Email" fullWidth />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Typography sx={{ fontWeight: "700", padding: "10px" }}>
                        Save my name, email, and website in this browser for the
                        next time I comment. Comment
                      </Typography>
                      <TextField label="Message" multiline rows={4} fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkbox defaultChecked />
                    </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" color="primary">
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default CardDetail;

import React, { useState, useEffect } from "react";
import Admin from "../Admin";
import { Grid } from "@mui/material";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { toDate } from "date-fns";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import "./ViewNews.css";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ViewNews = () => {
  const [viewNews, setViewNews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userCollection = collection(db, "news");
    const newsTableData = async () => {
      const data = await getDocs(userCollection);
      setViewNews(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    newsTableData();
  }, []);
  const handleDelete = async (id) => {
    console.log(id);
    const docRef = doc(db, "news", id);
    try {
      await deleteDoc(docRef);
      setViewNews((perViewNews) =>
        perViewNews.filter((data) => data.id !== id)
      );
    } catch (error) {
      alert("Fail", error);
    }
  };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Detail</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewNews.map((data) => {
                  const dateString = data.date;
                  const dateObject = new Date(dateString);
                  const options = {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  };

                  const timezoneOffset = dateObject.getTimezoneOffset();

                  const adjustedDate = new Date(
                    dateObject.getTime() + timezoneOffset * 60000
                  );

                  const formattedDate = adjustedDate.toLocaleString(
                    undefined,
                    options
                  );
                  console.log("Date............", formattedDate);

                  return (
                    <TableRow key={data.id}>
                      <TableCell>{data.title}</TableCell>
                      <TableCell>{data.detail}</TableCell>
                      <TableCell>{formattedDate.toLocaleString()}</TableCell>

                      <TableCell>
                        <Box sx={{ width: "100px" }}>
                          <img
                            src={data.image}
                            alt="Error"
                            className="table__image"
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <button
                          onClick={() => handleDelete(data.id)}
                          className="delete__btn"
                        >
                          Delete
                        </button>
                      </TableCell>
                      <TableCell>
                        <button
                          className="update__btn"
                          onClick={() => {
                            navigate("/updatenews", { state: data });
                          }}
                        >
                          Update
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewNews;

import "./App.css";
import { useEffect, useState } from "react";
import Header from "./components/header/Header";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import CardDetail from "./components/card/CardDetail";
import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Places from "./pages/places/Places";
import PlacesDetails from "./pages/places/PlacesDetails";
import News from "./pages/news/News";
import Admin from "./admin/Admin";
import ViewNews from "./admin/addNews/ViewNews";
import UpdateNews from "./admin/addNews/UpdateNews";
import Addplaces from "./admin/addPlaces/Addplaces";
import ViewPlaces from "./admin/addPlaces/ViewPlaces";
import UpdatePlaces from "./admin/addPlaces/UpdatePlaces";
import AddAbout from "./admin/about/AddAbout";
import ViewAbout from "./admin/about/ViewAbout";
import UpdateAbout from "./admin/about/UpdateAbout";
import AContact from "./admin/admincontact/AContact";
import GrainTypes from "./pages/grain_types/GrainTypes";
import AddGrainTypes from "./admin/graintypes/AddGrainTypes";
import VIewGrainTypes from "./admin/graintypes/ViewGrainTypes";
import ViewGrainTypes from "./admin/graintypes/ViewGrainTypes";
import UpdateGrainTypes from "./admin/graintypes/UpdateGrainTypes";
import ViewContact from "./admin/admincontact/ViewContact";
import UpdateAContact from "./admin/admincontact/UpdateAContact";
import RiceTypes from "./pages/ricetypes/RiceTypes";
import AddRiceTypes from "./admin/riceTypes/AddRiceTypes";
import ViewRiceTypes from "./admin/riceTypes/ViewRiceTypes";
import UpdateRiceTypes from "./admin/riceTypes/UpdateRiceTypes";
import Login from "./pages/login/Login";
import Loader from "./components/loader/Loader";
import ReactGA from "react-ga";

function App() {
  const [currentComponent, setCurrentComponent] = useState("");
  let title = "Rice  Pannel";
  useEffect(() => {
    setCurrentComponent(title);
    document.title = `${title}`;
  }, []);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  // const TRACKING_ID = "UA-273570611-1";
  const TRACKING_ID = " G-TZP5G82QKY";
  ReactGA.initialize(TRACKING_ID);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/carddetail" element={<CardDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/places" element={<Places />} />
          <Route path="/placesdetails" element={<PlacesDetails />} />
          <Route path="/news" element={<News />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/viewnews" element={<ViewNews />} />
          <Route path="/updatenews" element={<UpdateNews />} />
          <Route path="/addplaces" element={<Addplaces />} />
          <Route path="/viewplaces" element={<ViewPlaces />} />
          <Route path="/updateplaces" element={<UpdatePlaces />} />
          <Route path="/addabout" element={<AddAbout />} />
          <Route path="/viewabout" element={<ViewAbout />} />
          <Route path="/updateabout" element={<UpdateAbout />} />
          <Route path="/addcontact" element={<AContact />} />
          <Route path="/viewcontact" element={<ViewContact />} />
          <Route path="/graintypes" element={<GrainTypes />} />
          <Route path="/addgraintypes" element={<AddGrainTypes />} />
          <Route path="/viewgraintypes" element={<ViewGrainTypes />} />
          <Route path="/updategraintypes" element={<UpdateGrainTypes />} />
          <Route path="/updateacontact" element={<UpdateAContact />} />
          <Route path="/ricetypes" element={<RiceTypes />} />
          <Route path="/addricetypes" element={<AddRiceTypes />} />
          <Route path="/viewricetypes" element={<ViewRiceTypes />} />
          <Route path="/updatericetypes" element={<UpdateRiceTypes />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;

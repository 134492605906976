import React, { useRef, useEffect, useState } from "react";
import "./Header.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import headerLogo from "../../assests/logo/headerLogo.png";
import PrimarySearchAppBar from "./Drawer";
import AddHomeIcon from "@mui/icons-material/AddHome";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import card1 from "../../assests/cardsimg/card1.jpg";
import { width } from "@mui/system";
import Footer from "../footer/Footer";
import Cards from "../card/Card";
import card2 from "../../assests/cardsimg/card2.jpg";
import { addDoc, collection, getDocs } from "@firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { useDispatch, useSelector } from "react-redux";
import { addFooter } from "../../store/Reducers";
import { addhomeNews } from "../../store/Reducers";
import { addhomeRiceTypes } from "../../store/Reducers";
import { setHomePlace } from "../../store/Reducers";
import { query, where, orderBy, limit } from "firebase/firestore";
import BgHeader from "../bg_Header/BgHeader";
import { addPlaces } from "../../store/Reducers";
import Loader from "../loader/Loader";

const Header = () => {
  const news = useSelector((state) => state.news.news);

  // const grainTypes = useSelector((state) => state.grainTypes.grainTypes);

  const navigate = useNavigate();
  // const [news, setNews] = useState([]);
  const [grainTypes, setGrainTypes] = useState([]);
  const [limitedData, setLimitedData] = useState([]);
  const limitedNews = news.slice(0, 5);

  ///////////////////// Grain Types Start
  const limitedGrainData = grainTypes.slice(0, 8);
  // useEffect(() => {
  //   const grainTypesCollection = collection(db, "grainstype");

  //   const getGrainTypeData = async () => {
  //     const grainTypeData = await getDocs(grainTypesCollection);
  //     setGrainTypes(
  //       grainTypeData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //     );
  //     console.log(grainTypeData);
  //   };
  //   getGrainTypeData();
  // }, []);

  //////////////////////////////// GET New NewsData

  const [currentComponent, setCurrentComponent] = useState("");
  const dispatch = useDispatch();
  const homeNews = useSelector((state) => state.homeNews.homeNews);

  useEffect(() => {
    const newsCollection = collection(db, "news");
    const fetchNews = async () => {
      const newsQuery = query(
        newsCollection,
        orderBy("date", "desc"),
        limit(5)
      );
      const newsData = await getDocs(newsQuery);
      dispatch(
        addhomeNews(newsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
    };

    fetchNews();
  }, []);
  // const limitedNews = news.slice(0, 5);

  //////////////////////////////// GET New HomeRiceTypes Data
  const homeRiceTypes = useSelector(
    (state) => state.homeRiceTypes.homeRiceTypes
  );
  console.log(homeRiceTypes);

  useEffect(() => {
    const riceTypesCollection = collection(db, "ricetypes");
    const getRiceTypeData = async () => {
      const riceQuery = query(riceTypesCollection, limit(8));
      const riceTypeData = await getDocs(riceQuery);
      dispatch(
        addhomeRiceTypes(
          riceTypeData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
    };
    getRiceTypeData();
  }, []);
  const handleCardClicks = (data) => {
    setCurrentComponent(data.title);
    document.title = `Rice / ${data.title}`;
    navigate("/carddetail", { state: data });
  };

  //////////////////////////////// GET New HomeRiceTypes END

  /////// HOME POPULAR PLACES
  const homePlace = useSelector((state) => state.homePlace.homePlace);
  console.log(homePlace);

  useEffect(() => {
    const fetchData = async () => {
      const placesCollection = collection(db, "places");
      const placesQuery = query(
        placesCollection,
        orderBy("count", "desc"),
        limit(5)
      );
      const placesSnapshot = await getDocs(placesQuery);
      const updatedCards = placesSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      dispatch(setHomePlace(updatedCards));
    };

    fetchData();
  }, []);

  return (
    <>
      <header>
        {/* <Grid container>
          <Grid item xs={12}>
            <div className="header__bgImg">
              <div className="header__imgLogo">
                <a href="">
                  <img src={headerLogo} alt="" className="header__Logo" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid> */}

        <BgHeader />
        <Grid container>
          <Grid item xs={12}>
            <div className="navbar">
              <PrimarySearchAppBar />
            </div>
          </Grid>
        </Grid>
      </header>
      <div className="main__Sec">
        <Grid container>
          <Grid item lg={12}>
            <div className="card__Topline">
              <div className="cardTopline__txt">
                <h4>Todays News</h4>
                <hr className="hr" />
                <h4 className="cardTopline__txt__sech4">Most Popular</h4>
              </div>
            </div>
            <hr className=" card__Topline__hr" />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={2}>
              {homeNews.map((data, index) => {
                const dateString = data.date;
                const dateObject = new Date(dateString);
                const options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                };

                const timezoneOffset = dateObject.getTimezoneOffset();

                const adjustedDate = new Date(
                  dateObject.getTime() + timezoneOffset * 60000
                );

                const formattedDate = adjustedDate.toLocaleString(
                  undefined,
                  options
                );
                {
                  /* console.log("Date............", formattedDate); */
                }

                return (
                  <Grid
                    item
                    key={data.id}
                    lg={index < 2 ? 6 : 4}
                    md={index < 2 ? 6 : 4}
                    xs={12}
                  >
                    <Card className="news__card">
                      <a onClick={() => handleCardClicks(data)}>
                        <img
                          src={data.image}
                          alt="Error"
                          className="newscard__img"
                        />
                      </a>

                      <div className="news__date">
                        {formattedDate.toLocaleString()}
                      </div>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.detail.substring(0, 300),
                            }}
                          ></div>
                          ....
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {data.link}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        {/* <Button
                          size="small"
                          sx={{ color: "black" }}
                          onClick={() => handleCardClicks(data)}
                        >
                          Read More
                        </Button> */}
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3} md={12} sm={12}>
            <form className="side__form">
              <p>
                Get <span>Rice Panel </span>
                News headlines emailed to you
              </p>
              <p>Free</p>
              <p>Sign Up Now</p>
              <input type="text" placeholder="Enter Your Name" />
              <input type="text" placeholder="Enter Your Email" />
              <button className="form__btn">Click Me</button>
            </form>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <p className="news__head">RICE TYPES</p>
          </Grid>
          <Grid item xs={12} lg={3} md={12} sm={12}></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={2}>
              {homeRiceTypes.map((data, index) => (
                <Grid
                  item
                  key={data.id}
                  lg={index < 3 ? 3 : 3}
                  md={index < 3 ? 3 : 3}
                  sm={6}
                  xs={12}
                >
                  <Card className="grain__cards">
                    <img src={data.image} alt="" />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {data.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.detail.substring(0, 150),
                          }}
                        ></div>
                        ...
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <a
                        size="small"
                        sx={{ color: "black" }}
                        component={Link}
                        onClick={() => {
                          navigate("/ricetypes", { state: data });
                        }}
                      >
                        Read More
                      </a>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3} md={12} sm={12}></Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <p className="news__head">Popular Place</p>
          </Grid>
          <Grid item xs={12} lg={3} md={12} sm={12}></Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={9}>
            <Grid container spacing={2}>
              {homePlace.map((data, index) => (
                <Grid
                  item
                  key={data.id}
                  lg={index < 3 ? 3 : 3}
                  md={index < 3 ? 3 : 3}
                  sm={6}
                  xs={12}
                >
                  <Card className="grain__cards">
                    <img src={data.image} alt="" />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {data.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.detail,
                          }}
                        ></div>
                        ...
                      </Typography>
                      <div className="homePlace__counter">
                        Views: {data.count || 0}
                      </div>
                    </CardContent>

                    <CardActions>
                      <Button
                        size="small"
                        sx={{ color: "black" }}
                        onClick={() => {
                          navigate("/placesdetails", { state: data });
                        }}
                      >
                        Read More
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={3} md={12} sm={12}></Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default Header;

// import React, { useState, useEffect } from "react";

// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { Box } from "@mui/material";
// import { Grid } from "@mui/material";
// import { Link, useNavigate } from "react-router-dom";
// import { addDoc, collection, getDocs } from "@firebase/firestore";
// import { db } from "../../Firebase Setup/Firebase";
// import PrimarySearchAppBar from "../../components/header/Drawer";
// import "./News.css";
// import headerLogo from "../../assests/images/logo.png";
// import Footer from "../../components/footer/Footer";

// const News = ({ card2 }) => {
//   const navigate = useNavigate();
//   const [news, setNews] = useState([]);
//   useEffect(() => {
//     const newsCollection = collection(db, "news");
//     const getNewsData = async () => {
//       const newsData = await getDocs(newsCollection);
//       setNews(newsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
//       console.log(newsData);
//     };
//     getNewsData();
//   }, []);
//   const currentDate = new Date();
//   const formattedDate = `${currentDate.getDate()} ${currentDate.toLocaleString(
//     "default",
//     { month: "long" }
//   )} ${currentDate.getFullYear()}`;
//   const [selectedDate, setSelectedDate] = useState(null);

//   return (
//     <>
//       <Grid container>
//         <Grid item xs={12}>
//           <div className="header__bgImg">
//             <div className="header__imgLogo">
//               <a href="">
//                 <img src={headerLogo} alt="" />
//               </a>
//             </div>
//           </div>
//         </Grid>
//       </Grid>
//       <Grid container>
//         <Grid item xs={12}>
//           <div className="navbar">
//             <PrimarySearchAppBar />
//           </div>
//         </Grid>
//       </Grid>
//       <Box className="news__page">
//         <Grid container spacing={2}>
//           {news.map((data) => {
//             const dateString = data.date;
//             const dateObject = new Date(dateString);
//             const options = {
//               year: "numeric",
//               month: "numeric",
//               day: "numeric",
//             };

//             const timezoneOffset = dateObject.getTimezoneOffset();

//             const adjustedDate = new Date(
//               dateObject.getTime() + timezoneOffset * 60000
//             );

//             const formattedDate = adjustedDate.toLocaleString(
//               undefined,
//               options
//             );
//             console.log("Date............", formattedDate);
//             return (
//               <Grid item key={data.id} lg={4} md={4} sm={6} xs={12}>
//                 <Card className="news__card">
//                   <div className="news__date">
//                     {formattedDate.toLocaleString()}
//                   </div>
//                   <img src={data.image} alt="" className="newscard__img" />
//                   <CardContent>
//                     <Typography gutterBottom variant="h5" component="div">
//                       {data.title}
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       {data.detail}
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button
//                       size="small"
//                       sx={{ color: "black" }}
//                       onClick={() => navigate("/carddetail", { state: data })}
//                     >
//                       Read More
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//             );
//           })}
//         </Grid>
//       </Box>

//       <Footer />
//     </>
//   );
// };

// export default News;

import React, { useState, useEffect } from "react";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { addDoc, collection, getDocs } from "@firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import PrimarySearchAppBar from "../../components/header/Drawer";
import "./News.css";
import headerLogo from "../../assests/images/logo.png";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { setNews } from "../../store/Reducers";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { query, where, orderBy, limit } from "firebase/firestore";
import BgHeader from "../../components/bg_Header/BgHeader";
import Loader from "../../components/loader/Loader";
const News = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentComponent, setCurrentComponent] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const news = useSelector((state) => state.news.news);
  console.log("news", news);
  useEffect(() => {
    const fetchNews = async () => {
      const newsCollection = collection(db, "news");
      const newsQuery = query(
        newsCollection,
        orderBy("date", "desc"),
        // limit(10)
      );
      const newsData = await getDocs(newsQuery);
      dispatch(
        setNews(newsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
    };
    setIsLoading(false);

    fetchNews();
  }, []);
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 6;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = news.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleCardClicks = (data) => {
    setCurrentComponent(data.title);
    document.title = `Rice / ${data.title}`;
    navigate("/carddetail", { state: data });
  };

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <Box className="news__page">
        <Grid container spacing={2}>
          {isLoading ? (
            <div id="container">
              <label class="loading-title">Loading ...</label>
              <span class="loading-circle sp1">
                <span class="loading-circle sp2">
                  <span class="loading-circle sp3"></span>
                </span>
              </span>
            </div>
          ) : (
            <>
              {currentCards.map((data) => {
                const dateString = data.date;
                const dateObject = new Date(dateString);
                const options = {
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                };

                const timezoneOffset = dateObject.getTimezoneOffset();

                const adjustedDate = new Date(
                  dateObject.getTime() + timezoneOffset * 60000
                );

                const formattedDate = adjustedDate.toLocaleString(
                  undefined,
                  options
                );

                return (
                  <Grid item key={data.id} lg={4} md={4} sm={12} xs={12}>
                    <Card className="news__card">
                      <div className="news__date">
                        {formattedDate.toLocaleString()}
                      </div>
                      <a onClick={() => handleCardClicks(data)}>
                        <img
                          src={data.image}
                          alt="Error"
                          className="newscard__img"
                        />
                      </a>
                      {/* <img src={data.image} alt="" className="newscard__img" /> */}
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: data.detail,
                            }}
                          ></div>
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {data.link}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          sx={{ color: "black" }}
                          // onClick={() => navigate("/carddetail", { state: data })}
                          onClick={() => handleCardClicks(data)}
                        >
                          Read More
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                );
              })}
            </>
          )}
        </Grid>
        <Box
          spacing={2}
          sx={{ display: "flex", justifyContent: "center", mt: 5 }}
        >
          <Pagination
            count={Math.ceil(news.length / cardsPerPage)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </Box>

      <Footer />
    </>
  );
};
export default News;

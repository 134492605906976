import React, { useState } from "react";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";

import { Link } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { db, storage } from "../../Firebase Setup/Firebase";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
  connectStorageEmulator,
} from "firebase/storage";
import { addDoc, collection } from "@firebase/firestore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddRiceTypes = () => {
  const [title, settitle] = useState("");
  const [detail, setDetail] = useState("");

  const [fields, setFields] = useState([{ nature: "", specification: "" }]);
  const [image, setImage] = useState(null);
  const riceTypeCollection = collection(db, "ricetypes");
  const handleAddRices = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const storageRef = ref(storage, `rices/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageDownloadedUrl = await getDownloadURL(storageRef);
        console.log(imageDownloadedUrl);
        setImage("image", imageDownloadedUrl);

        await addDoc(riceTypeCollection, {
          title: title,
          detail: detail,

          image: imageDownloadedUrl,
          fields: fields,
        });
        alert("Data Sent");
      }
    } catch (error) {
      alert("Data Fail", error);
    }
  };

  const handleFieldChange = (index, fieldName, value) => {
    const updatedFields = [...fields];
    updatedFields[index][fieldName] = value;
    setFields(updatedFields);
  };

  const addField = (e) => {
    e.preventDefault();
    setFields([...fields, { nature: "", specification: "" }]);
  };
  const editorConfig = {
    placeholder: "Detail",
  };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <TextField
                  type=""
                  id="outlined-basic"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => settitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className="editor">
                  <CKEditor
                    className="ck__editor"
                    editor={ClassicEditor}
                    data={detail}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetail(data);
                    }}
                    config={{
                      height: 500,
                      ...editorConfig,
                    }}
                    // config={editorConfig}
                  />
                </Box>
              </Grid>
            </Grid>
            {fields.map((field, index) => (
              <Grid
                container
                spacing={2}
                alignItems="flex-end"
                key={index}
                marginTop="10px"
              >
                <Grid item xs={12} sm={6}>
                  <TextField
                    id={`nature-${index}`}
                    label="Nature"
                    variant="outlined"
                    value={field.nature}
                    onChange={(e) =>
                      handleFieldChange(index, "nature", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id={`specification-${index}`}
                    label="Specification"
                    variant="outlined"
                    value={field.specification}
                    onChange={(e) =>
                      handleFieldChange(index, "specification", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12} sm={6}>
              <button onClick={addField} className="button">
                Add More FieldS
              </button>
            </Grid>

            <TextField
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              sx={{ marginTop: "20px" }}
            ></TextField>
          </Box>
          <button onClick={handleAddRices} className="button">
            Add Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddRiceTypes;

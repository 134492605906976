// import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
// import { getFirestore } from "@firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyDDs6lpSNn3_x-Im92Oj7LLpjSiC8LW1rw",
//   authDomain: "graincentral-7116d.firebaseapp.com",
//   projectId: "graincentral-7116d",
//   storageBucket: "graincentral-7116d.appspot.com",
//   messagingSenderId: "753916580359",
//   appId: "1:753916580359:web:ac71515c54ab281085d0a2",
//   measurementId: "G-P1YSJGRJNE",
// };
// const app = initializeApp(firebaseConfig);

// export const storage = getStorage(app);
// export const db = getFirestore(app);

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDbZ-ypmV39FiGYB8PKSPyCboDvBa0D_u8",
  authDomain: "rice-panel-web.firebaseapp.com",
  projectId: "rice-panel-web",
  storageBucket: "rice-panel-web.appspot.com",
  messagingSenderId: "723542139047",
  appId: "1:723542139047:web:3e17ea0441d75db9f3fb82",
  measurementId: "G-PWGKV8YJ01",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);

import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Navbar from "../drawer/Navbar";
import Drawer from "../drawer/Drawer";
import { useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateNews = () => {
  const { state } = useLocation();
  const [updatetitle, setUpdateTitle] = useState("");
  const [updatedetail, setUpdateDetail] = useState("");
  const [updateDate, setUpdateDate] = useState("");
  const [updateimage, setUpdateImage] = useState("");
  const [imageUrl, setUpdateImageUrl] = useState("");

  useEffect(() => {
    console.log("Update Data", state);
    setUpdateTitle(state.title || "");
    setUpdateDetail(state.detail || "");
    setUpdateImage(state.image || "");
    setUpdateDate(state.date || "");
    setUpdateImageUrl(state.imageUrl || "");
  }, []);

  const dataCollection = collection(db, "news");

  const handleUpdateData = async (e, id) => {
    e.preventDefault();
    console.log(id);

    const docUpdate = doc(dataCollection, id);

    let imageUrlValue = imageUrl;
    if (updateimage) {
      const storageRef = ref(getStorage(), `news/${updateimage.name}`);
      await uploadBytes(storageRef, updateimage);
      imageUrlValue = await getDownloadURL(storageRef);
      console.log(imageUrlValue);
    }

    updateDoc(docUpdate, {
      title: updatetitle,
      detail: updatedetail,
      //   icon: iconUrlValue,
      image: imageUrlValue,
      date: updateDate,
    })
      .then(() => {
        alert("Data update");
      })
      .catch((error) => {
        alert("Error Message", error);
      });
  };

  //   const handleIconChange = (e) => {
  //     setIconUrl(URL.createObjectURL(e.target.files[0]));
  //     setIcon(e.target.files[0]);
  //   };

  const handleImageChange = (e) => {
    setUpdateImage(e.target.files[0]);
    setUpdateImageUrl(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-basic"
                  label="title"
                  variant="outlined"
                  value={updatetitle}
                  onChange={(e) => {
                    setUpdateTitle(e.target.value);
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <CKEditor
                    className="addServices__input"
                    editor={ClassicEditor}
                    data={updatedetail}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setUpdateDetail(data);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <TextField
              type="file"
              onChange={handleImageChange}
              sx={{ marginTop: "20px" }}
            ></TextField>
            <TextField
              type="date"
              id="outlined-basic"
              variant="outlined"
              value={updateDate}
              onChange={(e) => setUpdateDate(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 0px 0 20px",
              }}
            />
          </Box>
          <button
            type="submit"
            onClick={(e) => handleUpdateData(e, state.id)}
            className="button"
          >
            Update Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateNews;

import React, { useState } from "react";
import "./Admin.css";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Container, Grid, Typography } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import AddNews from "./addNews/AddNews";
import TextField from "@mui/material/TextField";
import Drawer from "./drawer/Drawer";
import Navbar from "./drawer/Navbar";
import { db } from "../Firebase Setup/Firebase";
import { storage } from "../Firebase Setup/Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { addDoc, collection } from "@firebase/firestore";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Admin = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState(null);
  const [selectedDate, setSelectedDate] = useState([]);
  const [link, setLink] = useState("");

  const newsCollection = collection(db, "news");
  const handleAddNews = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const storageRef = ref(storage, `news/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageDownloadedUrl = await getDownloadURL(storageRef);
        console.log(imageDownloadedUrl);
        setImage("image", imageDownloadedUrl);

        await addDoc(newsCollection, {
          title: title,
          detail: detail,
          link: link,
          date: selectedDate,
          image: imageDownloadedUrl,
        });
        alert("Data Sent");
      }
    } catch (error) {
      alert("Data Fail", error);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const editorConfig = {
    placeholder: "Detail",
  };
  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-basic"
                  label="title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <CKEditor
                    editor={ClassicEditor}
                    data={detail}
                    config={editorConfig}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetail(data);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>

            <TextField
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              sx={{ marginTop: "20px", width: "48%" }}
            ></TextField>

            <TextField
              type="date"
              id="outlined-basic"
              variant="outlined"
              value={selectedDate}
              onChange={(e) => setSelectedDate(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 0px 0 20px",
              }}
            />
            <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-basic"
                label="Link"
                variant="outlined"
                value={link}
                onChange={(e) => setLink(e.target.value)}
                fullWidth
                sx={{
                  margin: "20px 0px",
                }}
              />
            </Grid>
          </Box>

          <button onClick={handleAddNews} className="button">
            Add Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default Admin;

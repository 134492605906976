import React, { useState } from "react";

import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";

import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import { Box, Container, Grid } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
// import AddNews from "./addNews/AddNews";
import TextField from "@mui/material/TextField";
import FeedIcon from "@mui/icons-material/Feed";
import NearMeIcon from "@mui/icons-material/NearMe";
import InfoIcon from "@mui/icons-material/Info";
import ContactsIcon from "@mui/icons-material/Contacts";
import ChecklistIcon from "@mui/icons-material/Checklist";
import HomeIcon from "@mui/icons-material/Home";

const Drawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const [placesOpen, setPlacesOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [grainTypesOpen, setGrainTypesOpen] = useState(false);
  const [riceTypesOpen, setRiceTypesOpen] = useState(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleNewsOpen = () => {
    setNewsOpen(!newsOpen);
  };

  const handlePlaceOpen = () => {
    setPlacesOpen(!placesOpen);
  };
  const handleAboutOpen = () => {
    setAboutOpen(!aboutOpen);
  };
  const handleContactOpen = () => {
    setContactOpen(!contactOpen);
  };
  const handleGrainTypesOpen = () => {
    setGrainTypesOpen(!grainTypesOpen);
  };
  const handleRiceTypesOpen = () => {
    setRiceTypesOpen(!riceTypesOpen);
  };
  return (
    <>
      <Box>
        <List
          sx={{
            width: "100%",
            maxWidth: 250,
            display: { xs: "none", sm: "block" },
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <Link to="/">
              <ListItemText primary="Home" sx={{ color: "black" }} />
            </Link>
          </ListItemButton>
          <ListItemButton onClick={handleNewsOpen}>
            <ListItemIcon>
              <FeedIcon />
            </ListItemIcon>
            <ListItemText primary="News" />
            {newsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={newsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FeedIcon />
                </ListItemIcon>
                <Link to="/admin">
                  <ListItemText primary="Add News" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewnews">
                  <ListItemText primary="View News" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handlePlaceOpen}>
            <ListItemIcon>
              <NearMeIcon />
            </ListItemIcon>
            <ListItemText primary="Places" />
            {placesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={placesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/addplaces">
                  <ListItemText primary="Add Places" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewplaces">
                  <ListItemText primary="View Places" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleAboutOpen}>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="About" />
            {aboutOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={aboutOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/addabout">
                  <ListItemText primary="Add About" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewabout">
                  <ListItemText primary="View About" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          <ListItemButton onClick={handleContactOpen}>
            <ListItemIcon>
              <ContactsIcon />
            </ListItemIcon>
            <ListItemText primary="Contact" />
            {contactOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={contactOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/addcontact">
                  <ListItemText primary="Add Contact" sx={{ color: "black" }} />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewcontact">
                  <ListItemText
                    primary="View Contact"
                    sx={{ color: "black" }}
                  />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
          {/* <ListItemButton onClick={handleGrainTypesOpen}>
            <ListItemIcon>
              <ChecklistIcon />
            </ListItemIcon>
            <ListItemText primary="Grain Types" />
            {grainTypesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={grainTypesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/addgraintypes">
                  <ListItemText
                    primary="Add GrainType"
                    sx={{ color: "black" }}
                  />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewgraintypes">
                  <ListItemText
                    primary="View GrainTypes"
                    sx={{ color: "black" }}
                  />
                </Link>
              </ListItemButton>
            </List>
          </Collapse> */}
          <ListItemButton onClick={handleRiceTypesOpen}>
            <ListItemIcon>
              <ChecklistIcon />
            </ListItemIcon>
            <ListItemText primary="Rice Types" />
            {riceTypesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={riceTypesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/addricetypes">
                  <ListItemText
                    primary="Add RiceTypes"
                    sx={{ color: "black" }}
                  />
                </Link>
              </ListItemButton>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <Link to="/viewricetypes">
                  <ListItemText
                    primary="View RiceTypes"
                    sx={{ color: "black" }}
                  />
                </Link>
              </ListItemButton>
            </List>
          </Collapse>
        </List>
      </Box>
    </>
  );
};

export default Drawer;

import React, { useState } from "react";
import { db } from "../../Firebase Setup/Firebase";
import { storage } from "../../Firebase Setup/Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { Box, Container, Grid } from "@mui/material";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";
import { addDoc, collection } from "@firebase/firestore";
import TextField from "@mui/material/TextField";
const AContact = () => {
  const [address, setAddress] = useState("");
  const adminContactCollection = collection(db, "admincontact");
  const handleAdminContact = async (e) => {
    e.preventDefault();
    try {
      await addDoc(adminContactCollection, {
        address: address,
      });
      alert("Data Sent");
    } catch (error) {
      alert("Data Fail", error);
    }
  };
  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              sx={{
                width: "45%",
                margin: "0 20px 0 0",
              }}
            />
          </Box>
          <button onClick={handleAdminContact} className="button">
            Add Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default AContact;

import React, { useState } from "react";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";
import TextField from "@mui/material/TextField";
import { Box, Container, Grid } from "@mui/material";
import { addDoc, collection } from "@firebase/firestore";
import { storage } from "../../Firebase Setup/Firebase";
import { db } from "../../Firebase Setup/Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddAbout = () => {
  const [title, setTitle] = useState("");
  const [detail, setDetail] = useState("");
  const [image, setImage] = useState(null);

  const aboutCollection = collection(db, "about");
  const handleAddNews = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const storageRef = ref(storage, `about/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageDownloadedUrl = await getDownloadURL(storageRef);
        console.log(imageDownloadedUrl);
        setImage("image", imageDownloadedUrl);

        await addDoc(aboutCollection, {
          title: title,
          detail: detail,
          image: imageDownloadedUrl,
        });
        alert("Data Sent");
      }
    } catch (error) {
      alert("Data Fail", error);
    }
  };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12} container>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={6}>
                <TextField
                  id="outlined-basic"
                  label="title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <CKEditor
                    editor={ClassicEditor}
                    data={detail}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDetail(data);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <TextField
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              sx={{ marginTop: "20px" }}
            ></TextField>
          </Box>
          <button className="button" onClick={handleAddNews}>
            Add Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddAbout;

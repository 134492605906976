import React, { useState, useEffect } from "react";
import "./RiceTypes.css";
import PrimarySearchAppBar from "../../components/header/Drawer";
import headerLogo from "../../assests/images/logo.png";
import riceImage from "../../assests/images/ricetype1 (1).png";
import { Grid, Box, CardContent, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import { addDoc, collection, getDocs } from "@firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import BgHeader from "../../components/bg_Header/BgHeader";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
const RiceTypes = () => {
  const [getRiceTypes, setGetRiceTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRiceType, setSelectedRiceType] = useState({});
  const { state } = useLocation();
  console.log(state);

  // useEffect(() => {
  //   const riceCollection = collection(db, "ricetypes");
  //   const getRiceData = async () => {
  //     const riceData = await getDocs(riceCollection);
  //     setGetRiceTypes(
  //       riceData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //     );
  //     console.log(riceData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  //     if (riceData.docs.length > 0) {
  //       setSelectedRiceType(riceData.docs[0].data());
  //     }
  //     setIsLoading(false);
  //   };
  //   getRiceData();
  // }, []);

  useEffect(() => {
    const riceCollection = collection(db, "ricetypes");
    const getRiceData = async () => {
      const riceData = await getDocs(riceCollection);
      setGetRiceTypes(
        riceData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );

      if (state && state.id) {
        const selectedType = riceData.docs.find((doc) => doc.id === state.id);
        if (selectedType) {
          setSelectedRiceType(selectedType.data());
        }
      } else if (riceData.docs.length > 0) {
        setSelectedRiceType(riceData.docs[0].data());
      }

      setIsLoading(false);
    };
    getRiceData();
  }, [state]);
  const handleCardClick = (data) => {
    setSelectedRiceType(data);
    scrollToTop();
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <Box className="rice__types">
        <Grid container>
          <Grid item xs={12} lg={12} md={12}>
            <Grid container>
              {isLoading ? (
                <div id="container">
                  <label class="loading-title">Loading </label>
                  <span class="loading-circle sp1">
                    <span class="loading-circle sp2">
                      <span class="loading-circle sp3"></span>
                    </span>
                  </span>
                </div>
              ) : (
                <>
                  {getRiceTypes.length > 0 && (
                    <>
                      <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Box className="riceImage__div">
                          <img
                            src={selectedRiceType.image}
                            alt="error"
                            className="ricetypes__img"
                          />
                        </Box>
                      </Grid>
                      <Grid item lg={7} md={8} sm={6}>
                        <Typography variant="h4" component="p">
                          {selectedRiceType.title}
                        </Typography>
                        <Typography
                          variant="p"
                          component="p"
                          sx={{ lineHeight: "1.5", marginTop: "10px" }}
                          dangerouslySetInnerHTML={{
                            __html: selectedRiceType.detail,
                          }}
                        ></Typography>
                      </Grid>
                      <Grid item lg={12}>
                        <Typography
                          variant="h6"
                          component="p"
                          sx={{ marginTop: "10px" }}
                        >
                          Rice Specification Details
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell>Nature</TableCell>
                                <TableCell>Specification</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedRiceType.fields &&
                                selectedRiceType.fields.map((field, index) => (
                                  <TableRow key={index}>
                                    <TableCell>{field.nature}</TableCell>
                                    <TableCell>{field.specification}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item lg={12}>
                        <Box
                          className="riceCard "
                          gap={3}
                          id="riceTypesContainer"
                        >
                          <Grid container>
                            <Grid item xs={12} lg={12}>
                              <Grid container spacing={2}>
                                {getRiceTypes.map((data) => (
                                  <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    sm= {6}
                                    xs={12}
                                    key={data.id}
                                  >
                                    <Card
                                      className={`riceTypes__card ${
                                        selectedRiceType &&
                                        selectedRiceType.id === data.id
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => handleCardClick(data)}
                                    >
                                      <img
                                        src={data.image}
                                        alt=""
                                        className="riceTypes__Img"
                                      />

                                      <CardContent>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                        >
                                          {data.title}
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                          dangerouslySetInnerHTML={{
                                            __html: data.detail,
                                          }}
                                        ></Typography>
                                      </CardContent>
                                      <CardActions></CardActions>
                                    </Card>
                                  </Grid>
                                ))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default RiceTypes;

import React, { useState, useEffect } from "react";
import "./About.css";
import { Box, CardContent, Grid, Typography } from "@mui/material";
import PrimarySearchAppBar from "../../components/header/Drawer";
import logo from "../../assests/images/logo.png";
import Footer from "../../components/footer/Footer";
import { collection } from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { getDocs } from "firebase/firestore";
import BgHeader from "../../components/bg_Header/BgHeader";
import Loader from "../../components/loader/Loader";

const About = () => {
  const [about, getAbout] = useState([]);
  useEffect(() => {
    const aboutCollection = collection(db, "about");
    const getData = async () => {
      const data = await getDocs(aboutCollection);
      getAbout(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    getData();
  }, []);

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      {/*First Para */}
      <div className="about__page">
        <Grid container>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <CardContent>
              <Typography gutterBottom variant="h6" className="news__head">
                About Us
              </Typography>
            </CardContent>
          </Grid>
          <Grid item lg={9}>
            <Grid container>
              <Grid item lg={8} md={8} sm={6} xs={12}>
                {about.map((data) => {
                  console.log(data);
                  return (
                    <>
                      <Box key={data.id}>
                        <Typography variant="h4" component="p" margin="20px 0">
                          {data.title}
                        </Typography>
                        <Typography
                          variant="p"
                          component="p"
                          margin="20px 0"
                          dangerouslySetInnerHTML={{
                            __html: data.detail,
                          }}
                        ></Typography>
                        <img
                          src={data.image}
                          alt="Error"
                          className="about__img"
                        />
                      </Box>
                    </>
                  );
                })}
              </Grid>
              {/* <Grid itemlg={4} md={4} sm={6} xs={12}>
                <img src={logo} alt="Error" className="about__img" />
              </Grid>
              <Grid item lg={12}>
                <Typography>
                  Grain Central is independently owned and published by
                  experienced rural journalists, Jon Condon and James Nason, who
                  also produce similar online news services for the Australian
                  beef and sheep industries, Beef Central and Sheep Central.
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography variant="h4" component="p" margin="20px 0">
                  Why Grain Central?
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Typography>
                  Every day, Grain Central is updated with content written by
                  experienced specialists for the Australian grains and cropping
                  industry, including: local, national and international
                  industry news; grain market and price trends; domestic and
                  export supply and demand updates; property and machinery
                  developments; weather forecasts. Grain Central only writes
                  articles of interest to the grain industry – NO clickbait or
                  stories that don’t deliver what headlines promise! We believe
                  quality and important information should be available to
                  everyone in the industry, which is why we have chosen to
                  provide our content for free, supported by advertising
                  revenue, to ensure it is accessible to all users at no cost
                  and without the requirement of a paid subscription.
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" component="p" margin="20px ">
                  Editor: Liz Wells
                </Typography>
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <img src={logo} alt="Error" className="about__img" />
              </Grid>
              <Grid item lg={8} md={7} sm={6} xs={12}>
                <Typography>
                  Liz Wells was born and raised in Sydney, and gets her country
                  connection from holidays spent on her mother’s family’s grain
                  and sheep farm near Grenfell in NSW. After a stint at ABC
                  Radio and with a journalism degree in hand, Liz pursued the ag
                  journalism path in 1989 by joining The Land newspaper, which
                  saw her primarily cover central and southwest NSW. In 1995,
                  Liz went global by becoming Knight-Ridder’s
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>
                  Liz Wells was born and raised in Sydney, and gets her country
                  connection from holidays spent on her mother’s family’s grain
                  and sheep farm near Grenfell in NSW. After a stint at ABC
                  Radio and with a journalism degree in hand, Liz pursued the ag
                  journalism path in 1989 by joining The Land newspaper, which
                  saw her primarily cover central and southwest NSW. In 1995,
                  Liz went global by becoming Knight-Ridder’s
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" component="p" margin="20px ">
                  Journalist: Emma Alsop
                </Typography>
              </Grid>
              <Grid item lg={8} md={8} sm={12} xs={12}>
                <Typography>
                  Born at Inglewood in the Goondiwindi region and growing up
                  predominantly in Dalby on Queensland’s Western Downs, Emma has
                  a love for rural communities and a keen interest in the
                  agriculture industry. She completed her journalism studies at
                  the University of Southern Queensland in 2014 and, after a
                  short stint living in Brisbane, moved back to the Darling
                  Downs in 2015. Emma started her journalism career working for
                  small independent newspapers in townships across the Toowoomba
                  region, and spent five years covering the issues affecting the
                  community of Oakey.
                </Typography>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography>
                  <img src={logo} alt="" />
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" component="p" margin="20px ">
                  Editor: Liz Wells
                </Typography>
              </Grid> */}
              {/* <Grid item lg={4} md={5} sm={6} xs={12}>
                <img src={logo} alt="Error" className="about__img" />
              </Grid>
              <Grid item lg={8} md={7} sm={6} xs={12}>
                <Typography>
                  Liz Wells was born and raised in Sydney, and gets her country
                  connection from holidays spent on her mother’s family’s grain
                  and sheep farm near Grenfell in NSW. After a stint at ABC
                  Radio and with a journalism degree in hand, Liz pursued the ag
                  journalism path in 1989 by joining The Land newspaper, which
                  saw her primarily cover central and southwest NSW. In 1995,
                  Liz went global by becoming Knight-Ridder’s
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h5" component="p" margin="20px ">
                  Editor: Liz Wells
                </Typography>
              </Grid>
              <Grid item lg={4} md={5} sm={6} xs={12}>
                <img src={logo} alt="Error" className="about__img" />
              </Grid>
              <Grid item lg={8} md={7} sm={6} xs={12}>
                <Typography>
                  Liz Wells was born and raised in Sydney, and gets her country
                  connection from holidays spent on her mother’s family’s grain
                  and sheep farm near Grenfell in NSW. After a stint at ABC
                  Radio and with a journalism degree in hand, Liz pursued the ag
                  journalism path in 1989 by joining The Land newspaper, which
                  saw her primarily cover central and southwest NSW. In 1995,
                  Liz went global by becoming Knight-Ridder’s
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography>
                  During her earlier career in print media sales, Sally was also
                  the sales manager for ALFA Lotfeeding magazine, and sales
                  executive for the Australian Cotton Grains publication. She
                  grew up on a sheep/grain property at Hannaford in Western
                  Queensland, and now with her husband of 30 years, Steve,
                  enjoys her own small property at Pittsworth, running a small
                  herd of cattle and a few sheep. Sally’s primary sales role
                  covers the following areas: Rural property Grains and cropping
                  Commercial livestock and stud stock Contact Sally on 0419 471
                  578 or email sally@graincentral.com
                </Typography>
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item lg={3}></Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default About;

import React, { useState, useEffect } from "react";
import "./Footer.css";
import { Box, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import footerlogo from "../../assests/logo/footerlogo.png";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { fontSize } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { query, where, orderBy, limit } from "firebase/firestore";
import { addFooter, addfootergrainTypes } from "../../store/Reducers";
import footerLogo from "../../assests/logo/headerLogo.png";

const Footer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const footer = useSelector((state) => state.footer.footer);
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  console.log(currentDate);

  useEffect(() => {
    const newsCollection = collection(db, "news");
    const fetchNews = async () => {
      const newsQuery = query(
        newsCollection,
        orderBy("date", "desc"),
        limit(5)
      );
      const newsData = await getDocs(newsQuery);
      dispatch(
        addFooter(newsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
    };

    fetchNews();
  }, []);
  // const [user, setUser] = useState([]);
  // const userCollection = collection(db, "news");
  // useEffect(() => {
  //   const getUsers = async () => {
  //     const data = await getDocs(userCollection);
  //     setUser(data.docs.map((doc) => doc.data()));
  //   };
  //   getUsers();
  // }, []);

  ///////////Grain Types

  const footerGrainTypes = useSelector(
    (state) => state.footerGrainTypes.footerGrainTypes
  );

  useEffect(() => {
    const grainTypesCollection = collection(db, "ricetypes");

    const getGrainTypeData = async () => {
      const grainQuery = query(grainTypesCollection, limit(5));
      const grainTypeData = await getDocs(grainQuery);
      dispatch(
        addfootergrainTypes(
          grainTypeData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
    };
    getGrainTypeData();
  }, []);
  // const [grainTypes, setGrainTypes] = useState([]);
  // const grainCollection = collection(db, "grainstype");
  // useEffect(() => {
  //   const getGrainData = async () => {
  //     const data = await getDocs(grainCollection);
  //     setGrainTypes(data.docs.map((doc) => doc.data()));
  //   };
  //   getGrainData();
  // }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <div className="pre__bgimage">
            <div className="prefooter__icons">
              <div>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/">
                      <FacebookIcon />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.twitter.com/">
                      <TwitterIcon />
                    </a>
                  </li>

                  {/* <li>
                    <a href="https://www.instagram.com/">
                      <EmailIcon />
                    </a>
                  </li> */}
                </ul>
              </div>
              <h3>{currentDate}</h3>
            </div>
          </div>
        </Grid>
      </Grid>
      <Box className="main__footer">
        <div className="footer">
          <Grid container>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footer__left">
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className="lists__head"
                  >
                    Grain Central
                  </Typography>
                  <ul className="footer__left__ul">
                    <Link to="/">
                      <li>Home</li>
                    </Link>
                    <Link to="/about">
                      <li>About</li>
                    </Link>
                    <Link to="/contact">
                      <li>Contact</li>
                    </Link>
                    <Link to="/places">
                      <li>PLaces</li>
                    </Link>
                    <Link to="/news">
                      <li>News</li>
                    </Link>
                    <Link to="/ricetypes">
                      <li>Rice Types</li>
                    </Link>
                  </ul>
                </CardContent>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Box className="footer__second">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="lists"
                  sx={{ marginTop: "20px", color: "white", fontSize: "30px" }}
                >
                  Latest News
                </Typography>
                {footer.map((data) => (
                  <ul className="footer-menu" key={data.id}>
                    <li className="footer__lists">
                      <a
                        onClick={() => navigate("/carddetail", { state: data })}
                      >
                        {data.title.substring(0, 10)}
                        ...
                      </a>
                    </li>
                  </ul>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <Box className="footer__thi">
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  className="lists"
                  sx={{ marginTop: "20px", color: "white", fontSize: "30px" }}
                >
                  Rice Types
                </Typography>
                {footerGrainTypes.map((data) => (
                  <ul className="footer-menu" key={data.id}>
                    <li className="footer__lists">
                      <Link to="/ricetypes">{data.title} </Link>
                    </li>
                  </ul>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="footer__right">
                <CardContent>
                  <Link to="/">
                    <img
                      src={footerLogo}
                      alt="Error"
                      className="footer__logo"
                    />
                  </Link>
                </CardContent>
              </div>
            </Grid>
          </Grid>
        </div>
      </Box>
    </>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import { CardContent, Grid, Typography } from "@mui/material";
import PrimarySearchAppBar from "../../components/header/Drawer";
import logo from "../../assests/images/logo.png";
import Footer from "../../components/footer/Footer";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";

import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";

import { Box } from "@mui/material";

import { Link } from "react-router-dom";
import { addDoc, collection, getDocs } from "@firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import headerLogo from "../../assests/images/logo.png";
import "./GrainTypes.css";
import { useDispatch, useSelector } from "react-redux";
import { addGrainTypes } from "../../store/Reducers";
import BgHeader from "../../components/bg_Header/BgHeader";
import Loader from "../../components/loader/Loader";

const GrainTypes = () => {
  const dispatch = useDispatch();
  const grainTypes = useSelector((state) => state.grainTypes.grainTypes);
  console.log(grainTypes);

  useEffect(() => {
    const grainTypesCollection = collection(db, "grainstype");

    const getGrainTypeData = async () => {
      const grainTypeData = await getDocs(grainTypesCollection);
      dispatch(
        addGrainTypes(
          grainTypeData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        )
      );
    };
    getGrainTypeData();
  }, []);

  // const [grainTypes, setGrainTypes] = useState([]);
  // useEffect(() => {
  //   const grainTypesCollection = collection(db, "grainstype");

  //   const getGrainTypeData = async () => {
  //     const grainTypeData = await getDocs(grainTypesCollection);
  //     setGrainTypes(
  //       grainTypeData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
  //     );
  //     console.log(grainTypeData);
  //   };
  //   getGrainTypeData();
  // }, []);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return (
      <div className="loader">
        <div class="three-body">
          <div class="three-body__dot"></div>
          <div class="three-body__dot"></div>
          <div class="three-body__dot"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Loader />
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
        <Box className="grainTypes__page">
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={2}>
                {grainTypes.map((data) => (
                  <Grid item key={data.id} lg={4} md={6} xs={12}>
                    <Card className="grainTypes__card">
                      <img src={data.image} alt="" />
                      {/* <CardMedia sx={{ height: 300 }} image={data.image} className= "newsCard__image"/> */}
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {data.title}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: data.detail,
                          }}
                        ></Typography>
                      </CardContent>
                      <CardActions>
                        {/* <Link to="/carddetail">
                          <Button size="small">Read More</Button>
                        </Link> */}
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Footer />
    </>
  );
};

export default GrainTypes;

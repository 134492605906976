import React, { useState } from "react";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";
import { Grid, InputLabel } from "@mui/material";
import { storage } from "../../Firebase Setup/Firebase";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { addDoc, collection } from "@firebase/firestore";
import TextField from "@mui/material/TextField";
import { Box, Container } from "@mui/material";
import { db } from "../../Firebase Setup/Firebase";
import { Country, City } from "country-state-city";
import { Select, MenuItem } from "@mui/material";

const AddRiceTypes = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [newsOpen, setNewsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  // const [country, setCountry] = useState("");
  // const [city, setCity] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWesbite] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [image, setImage] = useState(null);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const placesCollection = collection(db, "places");
  const handleAddPlaces = async (e) => {
    e.preventDefault();
    try {
      if (image) {
        const storageRef = ref(storage, `places/${image.name}`);
        await uploadBytes(storageRef, image);
        const imageDownloadedUrl = await getDownloadURL(storageRef);
        console.log(imageDownloadedUrl);
        setImage("image", imageDownloadedUrl);

        await addDoc(placesCollection, {
          title: title,
          address: address,
          country: selectedCountry,
          city: selectedCity,
          phone: phone,
          email: email,
          website: website,
          lat: lat,
          long: long,
          image: imageDownloadedUrl,
        });
        alert("Data Sent");
      }
    } catch (error) {
      alert("Data Fail", error);
    }
  };

  const countries = Country.getAllCountries();
  const cities = selectedCountry
    ? City.getCitiesOfCountry(selectedCountry.isoCode)
    : [];

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const country = Country.getCountryByCode(countryCode);
    setSelectedCountry(country);
    setSelectedCity(null);
  };
  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const city = cityName ? cities.find((c) => c.name === cityName) : null;
    setSelectedCity(city);
  };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <TextField
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              sx={{
                width: "45%",
                margin: "0 20px 0 0",
              }}
            />
            <TextField
              label="Address"
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              sx={{
                width: "45%",
                margin: "0 20px 0 0",
              }}
            />
            {/* Countries and cities Start */}

            {/* <InputLabel htmlFor="country-select" sx={{ marginTop: "20px" }}>
              Enter Country
            </InputLabel> */}
            <Select
              label="Age"
              value={selectedCountry ? selectedCountry.isoCode : ""}
              onChange={handleCountryChange}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            >
              {countries.map((country) => (
                <MenuItem key={country.isoCode} value={country.isoCode}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>

            <Select
              value={selectedCity ? selectedCity.name : ""}
              onChange={handleCityChange}
              sx={{
                width: "45%",
                margin: "0 20px 0 0",
              }}
            >
              <MenuItem value="">Select a city</MenuItem>
              {cities.map((city) => (
                <MenuItem key={city.name} value={city.name}>
                  {city.name}
                </MenuItem>
              ))}
            </Select>

            {/* Countries and cities ENd */}
            <TextField
              label="Phone"
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            />
            <TextField
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            />
            <TextField
              label="Website"
              variant="outlined"
              value={website}
              onChange={(e) => setWesbite(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            />
            <TextField
              label="Latitude"
              variant="outlined"
              value={lat}
              onChange={(e) => setLat(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            />
            <TextField
              label="Longitude"
              variant="outlined"
              value={long}
              onChange={(e) => setLong(e.target.value)}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            />
            <TextField
              type="file"
              onChange={(e) => setImage(e.target.files[0])}
              sx={{
                width: "45%",
                margin: "20px 20px 0 0",
              }}
            ></TextField>
          </Box>
          <button className="button" onClick={handleAddPlaces}>
            Add Data
          </button>
        </Grid>
      </Grid>
    </>
  );
};

export default AddRiceTypes;

import React, { useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { Box, Container } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import { useState } from "react";
import { Link } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FeedIcon from "@mui/icons-material/Feed";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ClearIcon from "@mui/icons-material/Clear";
import headerLogo from "../../assests/logo/headerLogo.png";

import WrapTextIcon from "@mui/icons-material/WrapText";
import { Helmet } from "react-helmet";

export default function PrimarySearchAppBar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [currentComponent, setCurrentComponent] = useState("Rice  Pannel");

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const handleLinkClick = (component) => {
    setCurrentComponent(component);
    document.title = component ? `Rice  Pannel/ ${component}` : "Rice Pannel";
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* <Helmet>
        <title>{currentComponent ? `${currentComponent} ` : "TITLE"}</title>
      </Helmet> */}
      <AppBar position="static" sx={{ backgroundColor: " #59A3D9" }}>
        <Container>
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2, display: { md: "none", xs: "block" } }}
              onClick={toggleDrawer}
              className="drawer__icon"
            >
              <MenuIcon />
            </IconButton>
            <Box className="nav__lists">
              <Link to="/" onClick={() => handleLinkClick("Home")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>Home</li>
              </Link>
              <Link to="/about" onClick={() => handleLinkClick("about")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>About</li>
              </Link>
              <Link to="/contact" onClick={() => handleLinkClick("contact")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>Contact</li>
              </Link>
              <Link to="/places" onClick={() => handleLinkClick("places")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>Places</li>
              </Link>
              <Link to="/news" onClick={() => handleLinkClick("news")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>News</li>
              </Link>
              {/* <Link
                to="/graintypes"
                onClick={() => handleLinkClick("graintypes")}
              >
                <li sx={{ display: { xs: "none", sm: "block" } }}>
                  Grain Types
                </li>
              </Link> */}
              <Link
                to="/ricetypes"
                onClick={() => handleLinkClick("ricetypes")}
              >
                <li sx={{ display: { xs: "none", sm: "block" } }}>
                  Rice Types
                </li>
              </Link>
              {/* <Link to="/login" onClick={() => handleLinkClick("login")}>
                <li sx={{ display: { xs: "none", sm: "block" } }}>Login</li>
              </Link> */}
              {/* 
              <Link to="/admin">
                <li sx={{ display: { xs: "none", sm: "block" } }}>Admin</li>
              </Link> */}
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            {/* <input type="text" sx={{ padding: "20px" }} /> */}
          </Toolbar>
        </Container>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          width: "300px",
        }}
      >
        <List
          sx={{
            width: "300px",
            backgroundColor: "#004d70",
            height: "100%",
            color: "white",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <img src={headerLogo} alt="Error" className="drawer__logo" />
            <h1></h1>
            <Button color="inherit" onClick={closeDrawer}>
              <ClearIcon />
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1 }} />

          <ListItem button sx={{ marginTop: "20px" }}>
            <ListItemIcon>
              <HomeIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/" onClick={() => handleLinkClick("Home")}>
              <ListItemText primary="Home" />
            </Link>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <InfoIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/about" onClick={() => handleLinkClick("about")}>
              <ListItemText primary="About" />
            </Link>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <PhoneIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/contact" onClick={() => handleLinkClick("contact")}>
              <ListItemText primary="Contact" />
            </Link>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <LocationOnIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/places" onClick={() => handleLinkClick("places")}>
              <ListItemText primary="Places" />
            </Link>
          </ListItem>
          <ListItem button>
            <ListItemIcon>
              <FeedIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/news" onClick={() => handleLinkClick("news")}>
              <ListItemText primary="News" />
            </Link>
          </ListItem>
          {/* <ListItem button>
            <ListItemIcon>
              <ChecklistIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link
              to="/graintypes"
              onClick={() => handleLinkClick("graintypes")}
            >
              <ListItemText primary="Grain Types" />
            </Link>
          </ListItem> */}
          <ListItem button>
            <ListItemIcon>
              <WrapTextIcon sx={{ color: "white" }} />
            </ListItemIcon>
            <Link to="/ricetypes" onClick={() => handleLinkClick("ricetypes")}>
              <ListItemText primary="Rice Types" />
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}

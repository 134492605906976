import React, { useState, useEffect } from "react";
import Admin from "../Admin";
import { Grid } from "@mui/material";
import Drawer from "../drawer/Drawer";
import Navbar from "../drawer/Navbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";

import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ViewAbout = () => {
  const [viewAbout, setViewAbout] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const userCollection = collection(db, "about");
    const aboutTableData = async () => {
      const data = await getDocs(userCollection);
      setViewAbout(data.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    aboutTableData();
  }, []);
  const handleDelete = async (id) => {
    console.log(id);
    const docRef = doc(db, "about", id);
    try {
      await deleteDoc(docRef);
      setViewAbout((perViewNews) =>
        perViewNews.filter((data) => data.id !== id)
      );
    } catch (error) {
      alert("Fail", error);
    }
  };

  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item lg={9} md={8} sm={8} xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Detail</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Update</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {viewAbout.map((data) => (
                  <TableRow key={data.id}>
                    <TableCell>{data.title}</TableCell>
                    <TableCell>{data.detail}</TableCell>
                    <TableCell>
                      <Box sx={{ width: "100px" }}>
                        <img
                          src={data.image}
                          alt="Error"
                          className="table__image"
                        />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <button
                        onClick={() => handleDelete(data.id)}
                        className="delete__btn"
                      >
                        Delete
                      </button>
                    </TableCell>
                    <TableCell>
                      <button
                        className="update__btn"
                        onClick={() => {
                          navigate("/updateAbout", { state: data });
                        }}
                      >
                        Update
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewAbout;

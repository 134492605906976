import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import Navbar from "../drawer/Navbar";
import Drawer from "../drawer/Drawer";
import { useLocation } from "react-router-dom";
import {
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  onSnapshot,
  updateDoc,
  collection,
} from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const UpdateGrainTypes = () => {
  const { state } = useLocation();
  const [updatetitle, setUpdateTitle] = useState("");
  const [updatedetail, setUpdateDetail] = useState("");

  const [updateimage, setUpdateImage] = useState("");

  const [imageUrl, setUpdateImageUrl] = useState("");

  useEffect(() => {
    console.log("Update Data", state);
    setUpdateTitle(state.title || "");
    setUpdateDetail(state.detail || "");
    setUpdateImage(state.image || "");

    setUpdateImageUrl(state.imageUrl || "");
  }, []);

  const dataCollection = collection(db, "grainstype");

  const handleUpdateData = async (e, id) => {
    e.preventDefault();
    console.log(id);

    const docUpdate = doc(dataCollection, id);

    let imageUrlValue = imageUrl;
    if (updateimage) {
      const storageRef = ref(getStorage(), `grainstype/${updateimage.name}`);
      await uploadBytes(storageRef, updateimage);
      imageUrlValue = await getDownloadURL(storageRef);
      console.log(imageUrlValue);
    }

    updateDoc(docUpdate, {
      title: updatetitle,
      detail: updatedetail,

      image: imageUrlValue,
    })
      .then(() => {
        alert("Data update");
      })
      .catch((error) => {
        alert("Error Message", error);
      });
  };

  //   const handleIconChange = (e) => {
  //     setIconUrl(URL.createObjectURL(e.target.files[0]));
  //     setIcon(e.target.files[0]);
  //   };

  const handleImageChange = (e) => {
    setUpdateImage(e.target.files[0]);
    setUpdateImageUrl(URL.createObjectURL(e.target.files[0]));
  };
  return (
    <>
      <Navbar />
      <Grid container>
        <Grid item lg={3} md={4} sm={4} xs={12}>
          <Drawer />
        </Grid>
        <Grid item xs={12} sm={8} md={8} lg={9} container>
          <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{ width: "90%", marginTop: "100px" }}
            className="news__form"
          >
            <TextField
              className="addServices__input"
              type="text"
              name="title"
              placeholder="Update Title"
              value={updatetitle}
              onChange={(e) => {
                setUpdateTitle(e.target.value);
              }}
              sx={{
                width: "100%",
                margin: "0 20px 20px 0",
              }}
            />

            <CKEditor
              className="addServices__input"
              editor={ClassicEditor}
              data={updatedetail}
              onChange={(event, editor) => {
                const data = editor.getData();
                setUpdateDetail(data);
              }}
            />

            <TextField
              type="file"
              onChange={handleImageChange}
              sx={{ marginTop: "20px" }}
            ></TextField>
            <img
              src={updateimage}
              alt="Image Preview"
              className="addNews__image-preview"
            />
          </Box>
          <Grid container>
            <button
              variant="contained"
              type="submit"
              onClick={(e) => handleUpdateData(e, state.id)}
              className="button"
            >
              Update Data
            </button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateGrainTypes;

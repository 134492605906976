import React from "react";
import headerLogo from "../../assests/logo/headerLogo.png";
import Grid from "@mui/material/Grid";

const BgHeader = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div className="header__bgImg">
          <div className="header__imgLogo">
            <a href="">
              <img src={headerLogo} alt="" className="header__Logo" />
            </a>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default BgHeader;

import React, { useState, useEffect } from "react";
import PrimarySearchAppBar from "../../components/header/Drawer";
import { getDocs } from "firebase/firestore";
import { collection } from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import { Grid, Box, CardContent, Typography } from "@mui/material";
import placeDetailImg from "../../assests/images/placedetailimg.jpg";
import "./PlaceDetails.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Footer from "../../components/footer/Footer";
import { useLocation } from "react-router-dom";
import headerLogo from "../../assests/images/logo.png";
import BgHeader from "../../components/bg_Header/BgHeader";
import TwitterIcon from "@mui/icons-material/Twitter";
import Loader from "../../components/loader/Loader";

const PlacesDetails = () => {
  const { state } = useLocation();
  console.log("statedate...", state);

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <Box className="place__details">
        <Grid container>
          <>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Grid container sx={{ marginBottom: "30px" }}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <img src={state.image} alt="Error" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12}>
              <Box className="placecard__right">
                <Typography>Location/Contacts</Typography>
                <hr />

                {/* <iframe
                  src={`https://maps.google.com/maps?q=${state.lat},${state.long}&z=15&output=embed`}
                ></iframe> */}

                <button
                  className="contactButton"
                  onClick={() =>
                    window.open(
                      `https://maps.google.com/maps?q=${state.lat},${state.long}&z=15`
                    )
                  }
                >
                  Visit Map
                  <div className="iconButton">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                      ></path>
                    </svg>
                  </div>
                  <div className="gmap_canvas"></div>
                </button>
                <Typography>{state.address}</Typography>
                <hr />
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                >
                  <CallIcon sx={{ margin: "0 10px 0px 0px" }} />
                  <Typography>Phone : {state.phone} </Typography>
                </Box>
                <hr />

                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                  className="placeDetailWebsite__parent"
                >
                  <EmailIcon sx={{ margin: "0 10px 0px 0px" }} />
                  <p className="placeDetail__Email">Mail : {state.email}</p>
                </Box>

                <hr />
                <Box
                  sx={{
                    display: "flex",
                    padding: "10px",
                    width: "100%",
                    flexWrap: "wrap",
                  }}
                  className="placeDetailWebsite__parent"
                >
                  <WebAssetIcon sx={{ margin: "0 10px 0px 0px" }} />
                  <a href={state.website} className="placeDetail__Website">
                    Website : {state.website}
                  </a>
                </Box>
                <hr />
                <Box
                  sx={{
                    padding: "15px 10px",
                    display: "flex",
                    justifyContent: "space-around",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href="https://www.facebook.com/"
                    className="placeDetail__icons"
                  >
                    <FacebookIcon sx={{ fontSize: "35px" }} />
                  </a>

                  <a
                    href="https://www.twitter.com/"
                    className="placeDetail__icons"
                  >
                    <TwitterIcon sx={{ fontSize: "35px" }} />
                  </a>
                </Box>
                <Box></Box>
              </Box>
            </Grid>
          </>

          <Grid item lg={4} md={5} sm={12} xs={12}></Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  );
};

export default PlacesDetails;

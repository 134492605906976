import { configureStore } from "@reduxjs/toolkit";

import newsReducer, {
  footerGrainSLices,
  footerSlices,
  grainTypesSlices,
  homeRiceSlices,
  homeNewsSlices,
  homePlaceSlices,
  placesSlice,
} from "./Reducers";

const store = configureStore({
  reducer: {
    news: newsReducer,
    places: placesSlice.reducer,
    grainTypes: grainTypesSlices.reducer,
    footer: footerSlices.reducer,
    homeNews: homeNewsSlices.reducer,
    homeRiceTypes: homeRiceSlices.reducer,
    footerGrainTypes: footerGrainSLices.reducer,
    homePlace: homePlaceSlices.reducer,
  },
});

export default store;

import React, { useState, useEffect } from "react";
import "./Places.css";
import { Grid, Box, CardContent, Typography } from "@mui/material";
import PrimarySearchAppBar from "../../components/header/Drawer";
import PlacesCard from "./PlacesCard";
import headerLogo from "../../assests/images/logo.png";
import { addDoc, getDocs, setDoc, startAfter } from "firebase/firestore";
import { collection, doc } from "firebase/firestore";
import { db } from "../../Firebase Setup/Firebase";
import HotelIcon from "@mui/icons-material/Hotel";
import placeIcon from "../../assests/cardsimg/placeCardIcon.jpeg";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WifiIcon from "@mui/icons-material/Wifi";
import WineBarIcon from "@mui/icons-material/WineBar";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import Card from "@mui/material/Card";
import Footer from "../../components/footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { addPlaces } from "../../store/Reducers";
import { query, where, orderBy, limit } from "firebase/firestore";
import { updatePlaceCount } from "../../store/Reducers";
import BgHeader from "../../components/bg_Header/BgHeader";
import Loader from "../../components/loader/Loader";

const Places = () => {
  const navigate = useNavigate();
  const [getPlaces, setgetPlaces] = useState([]);
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places.places);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const placesCollection = collection(db, "places");
    const getPlacesData = async () => {
      const placesData = await getDocs(placesCollection);
      setgetPlaces(
        placesData.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    getPlacesData();
  }, []);

  useEffect(() => {
    const placesCollection = collection(db, "places");
    const getPlacesData = async () => {
      const placesQuery = query(placesCollection, limit(8));
      const placesData = await getDocs(placesQuery);

      dispatch(
        addPlaces(placesData.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      );
      setIsLoading(false);
    };
    getPlacesData();
  }, []);

  /////////// Pagination Code Stat
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 5;
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = places.slice(indexOfFirstCard, indexOfLastCard);
  const [currentComponent, setCurrentComponent] = useState("");

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  /////////// Pagination Code END

  const handleCardClicks = async (cardId) => {
    console.log(cardId);
    const clickedCard = places.find((card) => card.id === cardId);

    if (clickedCard) {
      setCurrentComponent(clickedCard.title);
      document.title = `Rice / ${clickedCard.title}`;
      const updatedCard = {
        ...clickedCard,
        count: (clickedCard.count || 0) + 1,
      };

      const updatedPlaces = places.map((place) => {
        if (place.id === cardId) {
          return updatedCard;
        }
        return place;
      });

      dispatch(addPlaces(updatedPlaces));
      dispatch(updatePlaceCount({ placeId: cardId, count: updatedCard.count }));
      const docRef = doc(db, "places", cardId);
      await setDoc(docRef, { count: updatedCard.count }, { merge: true });
    }
  };

  // const [isLoading, setIsLoading] = useState(true);
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const places = useSelector((state) => state.places.places);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const placesCollection = collection(db, "places");
  //       const placesQuery = query(
  //         placesCollection,
  //         orderBy("createdAt"),
  //         limit(8)
  //       );
  //       const placesSnapshot = await getDocs(placesQuery);

  //       const updatedCards = placesSnapshot.docs.map((doc) => ({
  //         ...doc.data(),
  //         id: doc.id,
  //       }));

  //       dispatch(addPlaces(updatedCards));
  //     } catch (error) {
  //       console.error("Error fetching places:", error);
  //     }
  //     setIsLoading(false);
  //   };

  //   fetchData();
  // }, []);

  // const [currentPage, setCurrentPage] = useState(1);
  // const cardsPerPage = 5;
  // const indexOfLastCard = currentPage * cardsPerPage;
  // const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  // const currentCards = places.slice(indexOfFirstCard, indexOfLastCard);
  // const [currentComponent, setCurrentComponent] = useState("");

  // const handlePageChange = (event, value) => {
  //   setCurrentPage(value);
  // };

  // const handleCardClicks = async (cardId) => {
  //   const clickedCard = places.find((card) => card.id === cardId);

  //   if (clickedCard) {
  //     setCurrentComponent(clickedCard.title);
  //     document.title = `Rice / ${clickedCard.title}`;
  //     const updatedCard = {
  //       ...clickedCard,
  //       count: (clickedCard.count || 0) + 1,
  //     };

  //     const updatedPlaces = places.map((place) =>
  //       place.id === cardId ? updatedCard : place
  //     );

  //     dispatch(addPlaces(updatedPlaces));
  //     dispatch(updatePlaceCount({ placeId: cardId, count: updatedCard.count }));

  //     // Update Firestore document
  //     try {
  //       const docRef = doc(db, "places", cardId);
  //       await setDoc(docRef, { count: updatedCard.count }, { merge: true });
  //     } catch (error) {
  //       console.error("Error updating place count:", error);
  //     }

  //     setCurrentComponent(clickedCard.title);
  //     document.title = `Rice / ${clickedCard.title}`;
  //   }
  // };

  return (
    <>
      <BgHeader />
      <Grid container>
        <Grid item xs={12}>
          <div className="navbar">
            <PrimarySearchAppBar />
          </div>
        </Grid>
      </Grid>
      <div className="places__page">
        <Grid container>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <CardContent>
              <Typography gutterBottom variant="h6" className="news__head">
                PLaces
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <div id="container">
                  <label class="loading-title">Loading </label>
                  <span class="loading-circle sp1">
                    <span class="loading-circle sp2">
                      <span class="loading-circle sp3"></span>
                    </span>
                  </span>
                </div>
              ) : (
                <>
                  {currentCards.map((data) => {
                    console.log(data);
                    return (
                      <>
                        <Grid item xs={12} sm={6} md={4} key={data.id}>
                          <Card sx={{ maxWidth: 450 }} className="placeCard">
                            <div className="placeCard__div">
                              <a
                                onClick={() => {
                                  navigate("/placesdetails", { state: data });
                                  handleCardClicks(data.id);
                                }}
                              >
                                <img
                                  src={data.image}
                                  alt="Error"
                                  className="placecard_img"
                                />
                              </a>

                              <div className="card__heart">
                                <FavoriteIcon className="cart__heartIcon" />
                              </div>
                              <div className="card__feature">
                                <p> Card Feature</p>
                              </div>

                              <div className="cardicon__line">
                                <div className="card__iconImg">
                                  <img src={placeIcon} alt="Error" />
                                </div>
                              </div>
                              <div className="card__counter">
                                <p className="cardCounter__txt">
                                  Views: {data.count || 0}
                                </p>
                              </div>
                            </div>

                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                              >
                                {data.title}
                              </Typography>
                              <Typography
                                gutterBottom
                                variant="p"
                                component="p"
                                color="text.secondary"
                                sx={{ margin: "10px 0px", fontSize: "15px" }}
                              >
                                <LocationOnIcon fontSize="10px" />{" "}
                                {data.address}
                              </Typography>
                              <hr />
                              <Typography
                                variant="body2"
                                margin="10px 0px"
                              ></Typography>
                              <Box sx={{ display: "flex" }}>
                                <Typography>Facilities:</Typography>
                                <Box sx={{ padding: " 0 10px" }}>
                                  <WifiIcon
                                    sx={{
                                      margin: "0px 10px 0px 0px",
                                      color: "#4db7fe",
                                    }}
                                  />
                                  <WineBarIcon
                                    sx={{
                                      margin: "0px 10px 0px 0px",
                                      color: "#4db7fe",
                                    }}
                                  />
                                  <WifiIcon
                                    sx={{
                                      margin: "0px 10px 0px 0px",
                                      color: "#4db7fe",
                                    }}
                                  />
                                  <WifiIcon
                                    sx={{
                                      margin: "0px 10px 0px 0px",
                                      color: "#4db7fe",
                                    }}
                                  />
                                </Box>
                              </Box>

                              <Box sx={{ display: "flex", marginTop: "10px" }}>
                                <Typography>Country:</Typography>
                                <Typography
                                  sx={{ color: "#4db7fe", marginLeft: "10px" }}
                                >
                                  {data.country.name}
                                </Typography>
                              </Box>
                              <Box sx={{ display: "flex", marginTop: "10px" }}>
                                <Typography>City:</Typography>
                                <Typography
                                  sx={{ color: "#4db7fe", marginLeft: "10px" }}
                                >
                                  {data.city.name}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Grid>
                      </>
                    );
                  })}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Box
          spacing={2}
          sx={{ display: "flex", justifyContent: "center", mt: 5 }}
        >
          <Pagination
            count={Math.ceil(places.length / cardsPerPage)}
            color="primary"
            page={currentPage}
            onChange={handlePageChange}
          />
        </Box>
      </div>

      <Footer />
    </>
  );
};

export default Places;
